<template>
	<div class="contact-form-wrapper">
		<div class="row">
			<div class="col-12 mx-auto">
				<div class="section-title text-center">
					<h3>{{ title ? title : '¡Contáctanos!' }}</h3>
				</div>
				<div v-if="company">
					<p>
						<v-icon dark :color="'#003a56'" style="margin-right: 5px"> mdi-check-decagram </v-icon>
						Solicita información acerca de esta Franquicia y conoce las oportunidades de inversión que te ofrece este
						Directorio
					</p>
					<p>
						<v-icon dark :color="'#003a56'" style="margin-right: 5px"> mdi-check-decagram </v-icon>
						¿Necesitas apoyo? Te brindamos asesoría en diferentes
						<a style="color: #0052cc !important" href="http://cogfranquicias.com/"> áreas de consultoría </a>
					</p>
				</div>
				<div v-else>
					<p>
						<v-icon dark :color="'#003a56'" style="margin-right: 5px"> mdi-check-decagram </v-icon>
						Solicita información acerca de las Franquicias y empresas que forman parte de ese directorio.
					</p>
					<p>
						<v-icon dark :color="'#003a56'" style="margin-right: 5px"> mdi-check-decagram </v-icon>Conoce acerca de
						nuestros servicios profesionales y areas de consultoría.
					</p>
					<p v-if="company && !company.name">
						<v-icon dark :color="'#003a56'" style="margin-right: 5px"> mdi-check-decagram </v-icon>Anuncia tu marca con
						nosotros y forma parte del directorio más amplio de Iberoamérica.
					</p>
				</div>
			</div>
			<v-row v-if="company && company.name" class="align-items-center justify-content-center w-100">
				<div class="cols-12 cols-sm-6 mx-auto">
					<h3>{{ company.name }}</h3>
				</div>
				<div class="cols-12 cols-sm-6 mx-auto">
					<v-img :src="company.logo.route" contain min-height="100" max-height="150"> </v-img>
				</div>
			</v-row>
		</div>
		<v-form ref="formResquest" v-model="valid" lazy-validation style="width: 100%; margin: 0px" class="row">
			<v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-top: 10px">
				<v-text-field :rules="[rules.required]" label="Nombre *" v-model="name" style="padding: 0px"></v-text-field>
			</v-col>
			<v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-top: 10px">
				<v-text-field
					:rules="[rules.required]"
					label="Apellido *"
					v-model="lastname"
					style="padding: 0px"
				></v-text-field>
			</v-col>
			<v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-top: 10px">
				<v-text-field
					style="padding: 0px"
					v-mask="phoneMask"
					:rules="[rules.required, rules.maxNumber]"
					label="Teléfono"
					v-model="phone"
					type="text"
				></v-text-field>
				<!--<div style="display: flex; align-items: center">
            <div class="row col-12" style="margin: 0px; padding-top: 0px">
              <label for="" style="font-size: 0.8rem; margin-top: -10px"
                >Teléfono *</label
              >
              <phone-mask-input
                v-model="phone"
                autoDetectCountry
                showFlag
                @onValidate="rules.required"
                wrapperClass="row wrraper-example"
                inputClass="input-example"
                flagClass="flag-example"
              />
            </div>
          </div>-->
			</v-col>
			<v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-top: 10px">
				<v-text-field :rules="[rules.required]" label="Email *" v-model="email" style="padding: 0px"></v-text-field>
			</v-col>
			<template v-if="company">
				<v-col cols="12" style="padding: 0px 10px; margin-bottom: 20px">
					<div class="" style="display: flex">
						<multiselect
							track-by="id"
							label="name"
							style="width: 85%"
							placeholder="Seleccionar país"
							v-model="countryId"
							:options="countries"
							:multiple="false"
							:close-on-select="true"
							:clear-on-select="false"
							:preserve-search="false"
						></multiselect>
						<v-icon slot="append" style="width: 15%" color="red"> mdi-map-marker-multiple </v-icon>
					</div>
				</v-col>
				<v-col cols="12" style="padding: 0px 10px; margin-top: 10px">
					<div class="">
						<multiselect
							track-by="name"
							label="name"
							placeholder="Servicio de interés"
							v-model="service"
							:options="selectService"
							:multiple="false"
							:close-on-select="true"
							:clear-on-select="false"
							:preserve-search="false"
						></multiselect>
						<!--<v-select
              ref="selectService"
              :rules="[rules.required]"
              :items="selectService"
              v-model="service"
              label="Servicio de interés"
              type="text"
              style="padding: 0px"
            ></v-select>-->
					</div>
				</v-col>

				<v-col cols="12" style="padding: 0px 10px; margin-top: 10px">
					<v-divider />
					<div class="">
						<v-text-field label="Nombre de tu empresa" v-model="business_name" style="padding: 0px"></v-text-field>
					</div>
				</v-col>
				<v-col cols="12" style="padding: 0px 10px; margin-top: 10px">
					<div class="">
						<multiselect
							track-by="name"
							label="name"
							placeholder="Actividad de tu empresa"
							v-model="business_type"
							:options="selectBusinessType"
							:multiple="false"
							:close-on-select="true"
							:clear-on-select="false"
							:preserve-search="false"
						></multiselect>
						<!--<v-select
								ref="businessType"
								@blur="hideMenu('businessType')"
								:items="selectBusinessType"
								:item-text="'name'"
								:item-value="'name'"
								v-model="business_type"
								label="Actividad de tu empresa"
								type="text"
								style="padding: 0px"
							></v-select>-->
					</div>
					<v-divider style="margin-top: calc(1rem + 8px)" />
				</v-col>
			</template>
			<!-- HOME SETUP FOR THESE FIELDS -->
			<template v-else>
				<v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-bottom: 20px">
					<div class="" style="display: flex">
						<multiselect
							track-by="id"
							label="name"
							style="width: 85%"
							placeholder="Seleccionar país"
							v-model="countryId"
							:options="countries"
							:multiple="false"
							:close-on-select="true"
							:clear-on-select="false"
							:preserve-search="false"
						></multiselect>
						<v-icon slot="append" style="width: 15%" color="red"> mdi-map-marker-multiple </v-icon>
					</div>
				</v-col>
				<v-col cols="12" lg="6" md="12" sm="12" style="padding: 0px 10px; margin-top: 10px">
					<div class="">
						<multiselect
							track-by="name"
							label="name"
							placeholder="Servicio de interés"
							v-model="service"
							:options="selectService"
							:multiple="false"
							:close-on-select="true"
							:clear-on-select="false"
							:preserve-search="false"
						></multiselect>
						<!--<v-select
              ref="selectService"
              :rules="[rules.required]"
              :items="selectService"
              v-model="service"
              label="Servicio de interés"
              type="text"
              style="padding: 0px"
            ></v-select>-->
					</div>
				</v-col>
				<v-col cols="12" lg="6" md="12" sm="12" style="padding: 0px 10px; margin-top: 10px">
					<div class="">
						<multiselect
							track-by="name"
							label="name"
							placeholder="Actividad de tu empresa"
							v-model="business_type"
							:options="selectBusinessType"
							:multiple="false"
							:close-on-select="true"
							:clear-on-select="false"
							:preserve-search="false"
						></multiselect>
						<!--<v-select
								ref="businessType"
								@blur="hideMenu('businessType')"
								:items="selectBusinessType"
								:item-text="'name'"
								:item-value="'name'"
								v-model="business_type"
								label="Actividad de tu empresa"
								type="text"
								style="padding: 0px"
							></v-select>-->
					</div>
				</v-col>
				<v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-top: 10px">
					<v-text-field label="Nombre de tu empresa" v-model="business_name" style="padding: 0px"></v-text-field>
				</v-col>
			</template>

			<v-col cols="12" lg="12" md="12" sm="12" style="padding: 0px 10px; margin-top: 10px">
				<v-textarea v-model="message" rows="2" counter="190" :rules="[rules.required, rules.max]" style="padding: 0px">
					<template v-slot:label>
						<div>Mensaje <small>*</small></div>
					</template>
				</v-textarea>
				<p style="font-size: 13px">
					Te informamos que XPOFranchise - Directorio de Franquicias cumple con la Ley de protección de datos de
					carácter personal, por lo que esta información es confidencial.
				</p>
				<p style="font-size: 13px">
					Te invitamos a conocer nuestros
					<a href="" style="text-decoration: underline">Términos y Políticas de Privacidad</a>
				</p>
				<div class="text-center">
					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						:disabled="!valid"
						:readonly="saving"
						:color="savingError ? '#F44336'  :'#003a56'"
						@click.prevent="validate"

					>
						{{ saving ? 'Enviando mensaje' : saved ? 'Mensaje enviado' : savingError ? 'Error al enviar' : 'Enviar Mensaje' }}
						<v-avatar size="16" v-if="saving">
							<v-progress-circular :size="20" :width="3" indeterminate />
						</v-avatar>
						<v-avatar size="16" v-else-if="saved">
							<v-icon icon="mdi-check" />
						</v-avatar>
					</v-btn>
				</div>
			</v-col>
		</v-form>
	</div>
</template>
<script>
	import SidebarLayoutTwo from 'Views/listing/SidebarLayoutTwo'

	export default {
		props: ['title', 'country', 'company', 'jobs'],
		components: {
			SidebarLayoutTwo: SidebarLayoutTwo
		},
		data() {
			return {
				loading: false,
				saving: false,
				saved: false,
				savingError: false,
				countries: [],
				cityId: 0,
				countryId: '',
				email: '',
				service: { name: 'Información de Franquicia o Franquicia Master' },
				name: '',
				lastname: '',
				date: new Date().toISOString(),
				phone: '',
				business_name: '',
				business_type: '',
				document_number: '',
				message: '',
				phoneMask: [
					'+',
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/,
					/[0-9, ]/
				],
				rules: {
					required: (v) => !!v || 'Requerido.',
					min: (v) => v.length >= 8 || '8 caracteres como mínimo',
					max: (v) => v.length <= 190 || 'Debe poser menos de 190 caracteres',
					maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
					url: (v) => this.isURL(v) || 'La URL es inválida'
				},
				selectService: [
					{ name: 'Información de Franquicia o Franquicia Master' },
					{ name: 'Información acerca de Proveedores u Oferta Inmobiliaria' },
					{ name: 'Unirse a Asociaciones de Franquicias' },
					{ name: 'Servicios de Consultoría y/o Asesoría' },
					{ name: 'Contratar espacios publicitarios' },
					{ name: 'Formar parte del Directorio y publicar o destacar tu negocio' }
					// { name: 'Anunciar en la revista Su Franquicia' },
				],
				selectBusinessType: [
					{ name: 'Academias comerciales' },
					{ name: 'Accesorios para coser, hilos, telas, etc' },
					{ name: 'Accesorios y Artículos Femeninos' },
					{ name: 'Aeropuertos' },
					{ name: 'Agencias de Empleo' },
					{ name: 'Agencias de Festejos' },
					{ name: 'Agencias de Publicidad' },
					{ name: 'Agencias de viaje' },
					{ name: 'Agua' },
					{ name: 'Aire Acondicionado' },
					{ name: 'Alfombras, Tapicerias' },
					{ name: 'Alquiler de Botes y Lanchas' },
					{ name: 'Alquiler de equipos y Herramientas' },
					{ name: 'Alquiler de trajes' },
					{ name: 'Alquiler de Vehículos' },
					{ name: 'Alquiler de video' },
					{ name: 'Apuestas, Peñas' },
					{ name: 'Articulos de Cuero' },
					{ name: 'Artículos de Zapaterías' },
					{ name: 'Artículos para Artes Plásticas' },
					{ name: 'Asesorias Profesionales' },
					{ name: 'Asoc. cooperativa' },
					{ name: 'Asociación civil' },
					{ name: 'Atracciones turisticas' },
					{ name: 'Autolavado' },
					{ name: 'Boutique Caballeros' },
					{ name: 'Boutique Damas' },
					{ name: 'Campos de Golf' },
					{ name: 'Canchas de bowling (boliches)' },
					{ name: 'Carpinteria' },
					{ name: 'Casa de Empeño' },
					{ name: 'Casas de Cambio' },
					{ name: 'Celulares y Accesorios' },
					{ name: 'Centro de Fotocopiado' },
					{ name: 'Club' },
					{ name: 'Combustibles (lubricantes, madera, carbo' },
					{ name: 'Comida Rapida, Restaurant (sin Bar)' },
					{ name: 'Condominio Residenciales (Servicios Publicos)' },
					{ name: 'Construcción' },
					{ name: 'Contadores y Auditores' },
					{ name: 'Contratistas de Electricidad' },
					{ name: 'Corredor de Seguros' },
					{ name: 'Correo Aereo y Terrestre' },
					{ name: 'Cortinas, Tapicerias' },
					{ name: 'Detectives, proteccion, blindados' },
					{ name: 'Discotecas, Tascas, Bares y similares' },
					{ name: 'Discotiendas' },
					{ name: 'Distribuidores y kioskos de peridódicos' },
					{ name: 'Droguerias y Farmacias' },
					{ name: 'Dulcerias, Bombonerias y Heladerías' },
					{ name: 'Electrodomésticos' },
					{ name: 'Equip. ortopedicos y quirurg.(riñones)' },
					{ name: 'Equipos agrop. e ind.' },
					{ name: 'Equipos de Oficina' },
					{ name: 'Equipos de Salud' },
					{ name: 'Equipos Electricos' },
					{ name: 'Escuelas y Colegios' },
					{ name: 'Escuelas, Academias, Estudios de danza' },
					{ name: 'Estación de servicio isla' },
					{ name: 'Estación de servicio tienda' },
					{ name: 'Estacionamientos' },
					{ name: 'Estudio Fotográfico' },
					{ name: 'Ferreterías' },
					{ name: 'Filatelia y Numismatica' },
					{ name: 'Financiamiento de Primas de Seguros' },
					{ name: 'Floristeria' },
					{ name: 'Funerarias' },
					{ name: 'Galerias de Arte' },
					{ name: 'Gas' },
					{ name: 'Gimnasios' },
					{ name: 'Gráfica' },
					{ name: 'Hipertiendas' },
					{ name: 'Hospitales y Clínicas' },
					{ name: 'Hoteleria' },
					{ name: 'Ingenieros y Arquitectos' },
					{ name: 'Inst. financieras, vta servici' },
					{ name: 'Instituciones Financieras' },
					{ name: 'Institutos Tecnológicos' },
					{ name: 'Instrumentos musicales' },
					{ name: 'Internet' },
					{ name: 'Joyeria' },
					{ name: 'Laboratorios Médicos, Odontológicos' },
					{ name: 'Latonería y Pintura' },
					{ name: 'Lavanderia y Limpieza' },
					{ name: 'Librerías' },
					{ name: 'Licoreria' },
					{ name: 'Limpeza de Alfombras y Tapiceria' },
					{ name: 'Limpieza y Lavado de Ventanas' },
					{ name: 'Linea aerea internacionales' },
					{ name: 'Líneas Aereas' },
					{ name: 'Médicos' },
					{ name: 'Mudanzas' },
					{ name: 'Muebles y Accesorios del Hogar' },
					{ name: 'Muebles y Artículos del Hogar' },
					{ name: 'Óptcicas' },
					{ name: 'Optometristas, oftalmologos' },
					{ name: 'Organizaciones Religiosas' },
					{ name: 'Organizaciones sin fines de lucro, Beneficencias.' },
					{ name: 'Ostheopatic physicians' },
					{ name: 'Pago de Impuestos' },
					{ name: 'Panaderia, Pasteleria y Cafes' },
					{ name: 'Papelerias y Utiles Escolares' },
					{ name: 'Parques de diversiones' },
					{ name: 'Peajes y Puentes' },
					{ name: 'Perfumeria, Maquillaje y Cosmeticos' },
					{ name: 'Periódico' },
					{ name: 'Plomeria' },
					{ name: 'Postage stamps' },
					{ name: 'Productos Petroliferos y a fines' },
					{ name: 'Productos Quimicos y a fines' },
					{ name: 'Proveedores de cavas y estantes' },
					{ name: 'Quiropracticos' },
					{ name: 'Redes de computacion/serv.de informacion' },
					{ name: 'Reparacion de articulos Electronicos y similares' },
					{ name: 'Reparación de Equipos de Audio y Video' },
					{ name: 'Reproducciones y copias' },
					{ name: 'Resort' },
					{ name: 'Restaurant de Lujo (con Bar)' },
					{ name: 'Ropa de Bebes y Niños' },
					{ name: 'Ropa Masculina y Femenina' },
					{ name: 'Ropa para Toda la Familia' },
					{ name: 'Salas de Cines Teatros y Museo' },
					{
						name: 'Salon de Belleza, Peluqueria, Manicuristas, Pedicuristas y similares'
					},
					{ name: 'Salones de billar y pool' },
					{ name: 'Salones de juego' },
					{ name: 'Salones de Masajes' },
					{ name: 'Sastres, Modistas, Arreglos y similares' },
					{ name: 'Serivicios Médicos en General' },
					{ name: 'Serv.de transport no clasificados' },
					{ name: 'Servicicos de Acompañantes' },
					{ name: 'Servicio de fumigacion' },
					{ name: 'Servicios de Ambulancias' },
					{ name: 'Servicios de campo (horticultura)' },
					{ name: 'Servicios de Computacion' },
					{ name: 'Servicios de cuidado infantil' },
					{ name: 'Servicios de Gruas' },
					{ name: 'Servicios de Piscinas' },
					{ name: 'Servicios de Telecomunicacion Nacional e Internacional' },
					{ name: 'Servicios domésticos' },
					{ name: 'Servicios gubernamentales no clasificado' },
					{ name: 'Servicios Legales, Abogados.' },
					{ name: 'Servicios Odontologicos' },
					{ name: 'Servicios Veterinarios' },
					{ name: 'Servicios y accesorios Marinos' },
					{ name: 'Talleres de aires acond. y eq. de refrig' },
					{ name: 'Talleres Mecánicos' },
					{ name: 'Taxis' },
					{ name: 'Teléfono' },
					{ name: 'Tienda de Articulos Religiosos' },
					{ name: 'Tienda de Ropa Deportiva' },
					{ name: 'Tiendas de Antiguedades' },
					{ name: 'Tiendas de Artículos deportívos y similares' },
					{ name: 'Tiendas de Computadoras Software' },
					{ name: 'Tiendas de delicateses' },
					{ name: 'Tiendas de Descuentos' },
					{ name: 'Tiendas de mascotas' },
					{ name: 'Tiendas de Pinturas, Barniz, Papel Tapiz y similares' },
					{ name: 'Tiendas de Productos lácteos' },
					{ name: 'Tiendas de radios, televisión, eq./sonid' },
					{ name: 'Tiendas de regalos, tarjeterias' },
					{ name: 'Tiendas de Variedades' },
					{ name: 'Tintorerias' },
					{ name: 'Toldos y persianas' },
					{ name: 'Transporte de pasajeros (Terrestre y Marítimo)' },
					{ name: 'Transporte, paradas de camiones' },
					{ name: 'TV por cable y similares' },
					{ name: 'U.k petrol stations, electronic hot file' },
					{ name: 'U.k. supermarkets, electronic hot file' },
					{ name: 'Uniformes' },
					{ name: 'Universidades' },
					{ name: 'Venta de bicicletas' },
					{ name: 'Ventas de lanchas' },
					{ name: 'Ventas de motos' },
					{ name: 'Ventas de respuestos para vehículos,camiones,motos y similares' },
					{ name: 'Ventas de vehículos y camiones' },
					{ name: 'Viveros, Equipos de jardineria y similares' },
					{ name: 'Zapaterías' },
					{ name: 'Zapateros' }
				],
				valid: false
			}
		},
		watch: {
			country(newValue, oldValue) {
				this.countryId = this.countries.filter((country) => country.id == newValue).shift()
			}
		},
		computed: {
			userId() {
				if (this.$session.get('tokenSession')) {
					return this.$session.get('userId')
				} else {
					return null
				}
			}
		},
		created() {
			this.getCountries()
		},
		mounted() {},
		methods: {
			hideMenu(ref) {
				this.$refs[ref].blur()
			},
			async getCountries() {
				this.loading = true
				axios
					.get(process.env.VUE_APP_API_DIRECTORY + 'countries?limit=100')
					.then((response) => {
						this.countries = response.data
						this.countryId = this.countries.filter((country) => country.id == this.country.id).shift()
						this.loading = false
					})
					.catch((error) => {})
					.finally(() => {})
			},

			validate() {
				if (this.$refs.formResquest.validate() && !this.saving && !this.savingError) {
					this.saveItem()
				}
			},
			async saveItem() {
				this.saving = true
				let dataPost = {
					name: this.name,
					lastname: this.lastname,
					country_id: this.countryId.id,
					document_number: 'N/A',
					business_name: this.business_name ? this.business_name : 'N/A',
					business_type: this.business_type ? this.business_type.name : 'N/A',
					message: this.message,
					phone: this.phone,
					email: this.email,
					service: this.service && this.service.name ? this.service.name : 'N/A',
					status: 'pending',
					checker_id: 0,
					check_time: this.date
				}
				if (this.company && this.company.id) {
					dataPost.company_id = this.company.id
				}
				if (this.userId) {
					dataPost.user_id = this.userId
				}
				axios
					.post(process.env.VUE_APP_API_DIRECTORY + 'form-requests', dataPost)
					.then((response) => {
						this.$swal({
							title: `¡Hola!`,
							html: `Hemos recibido tu solicitud.<br>Te contactaremos en las próximas horas`,
							showCancelButton: false,
							showConfirmButton: true,
							confirmButtonText: 'Aceptar'
						})
						this.$emit('sended')
						this.saved = true
						setTimeout(() => {
							this.saved = false
						}, 1000)
						// this.$refs.formResquest.reset()
						this.name = ''
						this.lastname = ''
						this.phone = ''
						this.email = ''
						this.countryId = null
						this.business_name = ''
						this.business_type = ''
						this.document_number = ''
						this.message = ''
						this.service.name = ''
					}).catch(() => {
						this.savingError = true
							setTimeout(() => {
							this.savingError = false
						}, 5000)
					})
					.finally(() => {
						this.saving = false
						
					})
			}
		}
	}
</script>

<style lang="scss">
	.col-centered {
		float: none;
		margin: 0 auto;
	}
	p {
		font-size: 0.9rem;
		font-weight: 300;
		line-height: 1.1;
	}

	.input-example {
		width: 80%;
		outline: -webkit-focus-ring-color auto 0px;
	}
	/*.flag-example {
		width: 20%;
	}*/
	.wrraper-example {
		display: flex;
		flex-wrap: wrap;
		flex: 1 1 auto;
		border-style: solid;
		border-width: 0 0 thin 0;
		bottom: -1px;
		content: '';
		left: 0;
		position: initial;
		transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
		width: 100%;
	}
</style>
